import React, { useState, useEffect } from "react";
import "./App.css";

export const themeManager = (darkMode = false) => {
  return {
    mode: !darkMode ? "light" : "dark",
    defaultFontColour: !darkMode ? "#414141" : "#fed",
    lightFontColour: !darkMode ? "#ffffff" : "#000",
    paleAccent: !darkMode ? "#f5f7fd" : "#33376a",
    midAccent: !darkMode ? "#dbdffc" : "#dbdffc",
    altAccent: !darkMode ? "#EAECFD" : "#8c8e98",
    accentColour: !darkMode ? "#413ddf" : "#9baacc",
    accentComplimentColour: !darkMode ? "#ff7346" : "#CCBD9B",
    greyTextColour: !darkMode ? "#6c6c6c" : "#999",
    mainBackgroundColour: !darkMode ? "#fff" : "#000437",
    errorColour: !darkMode ? "#E82E00" : "indianred",
    errorComplimentaryColour: !darkMode ? "#FBDBD4" : "#FBDBD4",
    authHeaderBG: !darkMode ? ["white", "white"] : ["#000", "#000"],
    subtleToBGColour: !darkMode ? "#f4f4f4" : "#000220",
    successColour: !darkMode ? "#2CC76D" : "#198754",
    successComplimentaryColour: !darkMode ? "#E9F9F0" : "#E9F9F0",
    warningColour: !darkMode ? "#FFAA00" : "#FFAA00",
    webNavy: !darkMode ? "#2e3440" : "#2e3440",
    webSilverBorders: !darkMode
      ? "rgba(100, 150, 200,0.3)"
      : "rgba(100, 150, 200,0.3)",
    textInputTextColour: !darkMode ? "rgba(0, 0, 0, 0.87)" : "#ddd",
    textInputPlaceholderColour: !darkMode ? "rgba(0, 0, 0, 0.6)" : "#ddd",
    textInputMediumColour: !darkMode ? "rgba(0, 0, 0, 0.4)" : "#fff",
    textInputBorderColour: !darkMode
      ? "rgba(0, 0, 0, 0.12)"
      : "rgba(255,255,255,0.2)",
  };
};
function App() {
  const [english, setEnglish] = useState(true);
  const [scrollViewHeight, setScrollViewHeight] = useState(
    window.innerHeight - 250
  );

  useEffect(() => {
    const handleResize = () => {
      setScrollViewHeight(window.innerHeight - 250);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  let androidLink = "https://groups.google.com/g/lokate-android-beta";
  let androidTitle = "Download on Play Store";

  let iosLink = "https://testflight.apple.com/join/mQ5fD96J";
  let iosTitle = "Download on TestFlight";

  let webLink = "https://transport-planner.lokate.io/";
  let webTitle = "Open Transport Planner Web";

  return (
    <section className="App">
      <main className="App-main">
        <header className="App-header">
          <img
            src={`../img/lokate-light-logo.svg`}
            height={40}
            alt={"Lokate"}
          />
          {
            <div className="language-toggle">
              <button
                className={english ? "selected" : ""}
                onClick={() => setEnglish(true)}
              >
                {" "}
                English
              </button>
              <button
                className={!english ? "selected" : ""}
                onClick={() => setEnglish(false)}
              >
                {" "}
                Svenska
              </button>
            </div>
          }
        </header>
        <section className="Container">
          {english ? (
            <div className="TextContainer">
              <h1>Welcome to Lokate!</h1>

              <h3>Are you a Supplier? </h3>
              <p style={{ textAlign: "left" }}>
                <a href={webLink} target="_blank" title={webTitle}>
                  Click here
                </a>{" "}
                to visit the supplier website
              </p>
              <h3>
                Are you a Distributor/Driver or work at a Construction Site?
              </h3>
              <p style={{ textAlign: "left" }}>
                To get started, scan the code with your camera or click the link
                corresponding to the platform you are using.
              </p>
            </div>
          ) : (
            <div className="TextContainer">
              <h1>Välkommen till Lokate!</h1>
              <h3>Är du Leverantör? </h3>

              <p style={{ textAlign: "left" }}>
                <a href={webLink} target="_blank" title={webTitle}>
                  Klicka här
                </a>{" "}
                för att besöka leverantörens webbplats
              </p>
              <h3>
                Är du Distributör/Förare eller jobbar på en Byggarbetsplats?
              </h3>
              <p style={{ textAlign: "left" }}>
                För att komma igång, skanna koden med din kamera eller klicka på
                länken till den platform du använder.
              </p>
            </div>
          )}
          <div className="Code-container">
            <div className="Code">
              <img
                src={`../img/android.svg`}
                alt="Android"
                className="device-logo"
              />

              <a
                href={androidLink}
                title={androidTitle}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={`../img/ANDROID-QR.png`}
                  alt={androidTitle}
                  className="qr"
                />
              </a>
              <a
                href={androidLink}
                style={{ color: "#413ddf", padding: "10px" }}
                title={androidTitle}
                target="_blank"
                rel="noreferrer"
              >
                Android - Play Store
              </a>
            </div>
            <div className="Code">
              <img src={`../img/ios.svg`} alt="iOS" className="device-logo" />

              <a
                href={iosLink}
                title={iosTitle}
                target="_blank"
                rel="noreferrer"
              >
                <img src={`../img/iOS-QR.png`} alt={iosTitle} className="qr" />
              </a>
              <a
                href={iosLink}
                style={{ color: "#413ddf", padding: "10px" }}
                title={iosTitle}
                target="_blank"
                rel="noreferrer"
              >
                iOS - TestFlight
              </a>
            </div>
          </div>
        </section>
      </main>
    </section>
  );
}

export default App;
