import React, { useState, useEffect } from "react";
import "../App.css";
import { LegalInfo } from "./LegalInfo";

function UserTerms() {
  return (
    <section className="App">
      <main className="App-main">
        <header className="App-header">
          <a href="/">
            <img
              src={`../img/lokate-light-logo.svg`}
              height={40}
              alt={"Lokate"}
            />
          </a>
        </header>
        {LegalInfo}
      </main>
    </section>
  );
}

export default UserTerms;
