import React from "react";
import "./App.css";

export const currentAndroidVersion = "4.00 (138)";
export const currentIosVersion = "4.00 (138)";
export const currentWebVersion = "sPRODw43";

const notes = `
- Fixed: iOS keyboard issue causing the screen to shift up.
- Removed: Unnecessary unloading equipment box in Site app.
- Improved: TPS web UI.
- Fixed: Missing driver info in TPS delivery history.
- Fixed: Issue preventing flagging important deliveries in Site app.
- Fixed: Default DDP not working and unable to change default DDP.
- Updated: Supplier Public API with delivery update rules.
- Improved: TPS UI.
- Fixed: Max 3 letters now shown in TPS initials circle.
- Fixed: Issue accepting project invitations from Site app dashboard.
- Fixed: "You've joined a project" message cut off on Android devices.
- Fixed: Organisation Number field clearing phone number during TPS delivery edit.
- Added: TPS version number.
- Fixed: Incorrect time display in Site app (range issue).
- Fixed: "You've joined a project" message cut off on certain Android devices (Pixel 4a).
- Updated: Site app unloading equipment status to "NONE."
- Fixed: Tracking zoom resetting to default in Site app.
- Fixed: Driver app showing incorrect information.
- Fixed: TPS re-ordering route legs marking "Completed" deliveries as "InTransit."
`;

const ReleaseNotes = () => {
  return (
    <section className="App">
      <main className="App-main">
        <header className="App-header">
          <img src={`../img/lokate-light-logo.svg`} height={40} />
        </header>
        <section className="Container">
          <div className="released-version-wrapper">
            <div className="VersionTextContent">
              android version: {currentAndroidVersion}
            </div>
            <div className="VersionTextContent">
              ios version: {currentIosVersion}
            </div>
            <div className="VersionTextContent">
              web version: {currentWebVersion}
            </div>
          </div>
          <div
            style={{
              textAlign: "left",
              fontWeight: "bold",
              color: "darkslategray",
            }}
          >
            Latest Release notes:
          </div>
          <br></br>
          {notes.split("- ").map((line, i) => (
            <div
              style={{
                textAlign: "left",
                paddingLeft: "20px",
                paddingBottom: "5px",
                color: "darkslategray",
              }}
              key={"note_" + i}
            >
              {i != 0 && "- "}
              {line}
            </div>
          ))}
        </section>
      </main>
    </section>
  );
};

export default ReleaseNotes;
