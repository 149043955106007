export const LegalInfo = (
  <section className="PrivacyContainer">
    <div className="TextContainer">
      <div>
        <h1>Lokate User Terms</h1>
        <ol
          className="decimal_type"
          style={{ listStyleType: "decimal", fontSize: "0.8rem" }}
        >
          <h2
            style={{
              marginTop: "6.0pt",
              marginRight: "0cm",
              marginBottom: "6.0pt",
              fontSize: "0.8rem",
              fontFamily: '"Arial",sans-serif',
            }}
          >
            Background
          </h2>
          <p
            style={{
              marginTop: "6.0pt",
              marginRight: "0cm",
              marginBottom: "6.0pt",
              marginLeft: "10.0pt",
              textAlign: "justify",
              textIndent: "0cm",
              fontSize: "0.8rem",
              fontFamily: '"Arial",sans-serif',
              fontWeight: "normal",
            }}
          >
            Welcome to Lokate! Lokate is a logistics service offering a digital
            platform that enables information sharing about the ordering,
            planning, coordination and tracking of transportation of goods (the{" "}
            <b>“Service”</b>). The Service is accessed via the Lokate mobile
            application (the <b>“App”</b>) (which you can download through your
            app store of choice) and the Lokate website (
            <a
              href="https://lokate.io/"
              target="_blank"
              style={{ fontSize: "0.8rem" }}
            >
              https://lokate.io/
            </a>
            ). To access and use the Service, a personal Lokate account is
            needed (<b>“User Account”</b>). These User Terms govern your use of
            your User Account and access to the Service and constitutes an
            agreement between you (the person accepting these User Terms), and
            Volvo Construction Equipment AB (<b>“Volvo”</b>, <b>“we”</b>, or{" "}
            <b>“us”</b>).
          </p>
          <li>
            <h2
              style={{
                marginTop: "6.0pt",
                marginRight: "0cm",
                marginBottom: "6.0pt",
                fontSize: "0.8rem",
                fontFamily: '"Arial",sans-serif',
              }}
            >
              The Lokate Service
            </h2>
            <ol
              className="decimal_type"
              style={{
                listStyleType: "decimal",
                fontSize: "0.8rem",
                marginLeft: "20.0pt",
              }}
            >
              <li>
                <p
                  style={{
                    marginTop: "6.0pt",
                    marginRight: "0cm",
                    marginBottom: "6.0pt",
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "0cm",
                    fontSize: "0.8rem",
                    fontFamily: '"Arial",sans-serif',
                    fontWeight: "normal",
                  }}
                >
                  The Service is a work tool. The Service is not for personal
                  use and is not offered to consumers. You have no rights
                  relating to the access and performance of the Service.
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginTop: "6.0pt",
                    marginRight: "0cm",
                    marginBottom: "6.0pt",
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "0cm",
                    fontSize: "0.8rem",
                    fontFamily: '"Arial",sans-serif',
                    fontWeight: "normal",
                  }}
                >
                  You may only use the Service for its intended purpose, in
                  compliance with applicable laws, and subject all other
                  instructions provided by Volvo.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2
              style={{
                marginTop: "6.0pt",
                marginRight: "0cm",
                marginBottom: "6.0pt",
                fontSize: "0.8rem",
                fontFamily: '"Arial",sans-serif',
              }}
            >
              Terms of Use
            </h2>
            <ol
              className="decimal_type"
              style={{
                listStyleType: "decimal",
                fontSize: "0.8rem",
                marginLeft: "20.0pt",
              }}
            >
              <li>
                <p
                  style={{
                    marginTop: "6.0pt",
                    marginRight: "0cm",
                    marginBottom: "6.0pt",
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "0cm",
                    fontSize: "0.8rem",
                    fontFamily: '"Arial",sans-serif',
                    fontWeight: "normal",
                  }}
                >
                  You are prohibited from using, or in any way facilitating or
                  supporting to use the Service:
                </p>
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <li style={{ paddingBottom: "5px" }}>
                    for any unlawful, illegal, fraudulent, infringing, harmful,
                    or offensive use,
                  </li>
                  <li style={{ paddingBottom: "5px" }}>
                    for any activities that may harm the operations or
                    reputation of Volvo or our customers,
                  </li>
                  <li style={{ paddingBottom: "5px" }}>
                    to store, distribute or otherwise making available data that
                    infringes or misappropriates the intellectual property of
                    others or that is defamatory, obscene, abusive, invasive of
                    privacy or otherwise objectionable,
                  </li>
                  <li style={{ paddingBottom: "5px" }}>
                    to submit to the Service any information in a free text
                    field relating directly or indirectly to an individual
                    (personal data) unless specifically requested. Personal data
                    can be for example name, contact details, photo, vehicle
                    identification number (VIN), and vehicle registration
                    number.
                  </li>
                </ul>
              </li>
              <li>
                <p
                  style={{
                    marginTop: "6.0pt",
                    marginRight: "0cm",
                    marginBottom: "6.0pt",
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "0cm",
                    fontSize: "0.8rem",
                    fontFamily: '"Arial",sans-serif',
                    fontWeight: "normal",
                  }}
                >
                  You may not violate, or attempt to violate, the security or
                  integrity of the Service or any related software, hardware, or
                  digital infrastructure (e.g., transmitting malicious code),
                  nor are you allowed to access the Service in any fraudulent or
                  unauthorized way, attempting to reverse engineer, decompile or
                  otherwise derive or attempt to discover source code, object
                  code, trade secrets, know-how or algorithms of the Service,
                  and any other activity that threatens the confidentiality,
                  integrity, and availability of the Service.
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginTop: "6.0pt",
                    marginRight: "0cm",
                    marginBottom: "6.0pt",
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "0cm",
                    fontSize: "0.8rem",
                    fontFamily: '"Arial",sans-serif',
                    fontWeight: "normal",
                  }}
                >
                  Volvo reserves the right to suspend Users that violate the
                  User Terms, and we will take any other actions deemed
                  necessary by us to protect Volvo’s interests and the interests
                  of our customers and other third parties. Upon request, you
                  will be required to provide us with proof of compliance with
                  these User Terms. We reserve the right to terminate these User
                  Terms if we determine, in our sole discretion, that you are in
                  violation of any provision of these User Terms.
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2
              style={{
                marginTop: "6.0pt",
                marginRight: "0cm",
                marginBottom: "6.0pt",
                fontSize: "0.8rem",
                fontFamily: '"Arial",sans-serif',
              }}
            >
              Data Protection
            </h2>
            <ol
              className="decimal_type"
              style={{
                listStyleType: "decimal",
                fontSize: "0.8rem",
                marginLeft: "20.0pt",
              }}
            >
              <li>
                <p
                  style={{
                    marginTop: "6.0pt",
                    marginRight: "0cm",
                    marginBottom: "6.0pt",
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "0cm",
                    fontSize: "0.8rem",
                    fontFamily: '"Arial",sans-serif',
                    fontWeight: "normal",
                  }}
                >
                  When using the Service, Volvo will process Personal Data
                  relating to you. This includes information that you provide
                  during the User Account registration (e.g., your name and
                  contact details). For administration of your User Account,
                  Volvo is the data controller and you can find information
                  about this processing of Personal Data in the Volvo Group
                  privacy notice for Customer Representative (available at&nbsp;
                  <a
                    href="https://www.volvogroup.com/en/tools/privacy/customer-representative.html"
                    target="_blank"
                    style={{ fontSize: "0.8rem", wordBreak: "break-all" }}
                  >
                    https://www.volvogroup.com/en/tools/privacy/customer-representative.html
                  </a>
                  ).
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginTop: "6.0pt",
                    marginRight: "0cm",
                    marginBottom: "6.0pt",
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "0cm",
                    fontSize: "0.8rem",
                    fontFamily: '"Arial",sans-serif',
                    fontWeight: "normal",
                  }}
                >
                  If you are using the Service as a driver of goods, the App
                  will also collect information about your mobile device’s GPS
                  location in order to estimate the time of delivery and to
                  visualize your proximity to the delivery site on a map for the
                  delivery site that is your next stop. The App will always
                  clearly indicate whether GPS location tracking is activated,
                  and you will always be able to pause the tracking. Other
                  information about you that is included in connection to your
                  use of the Service may be shared with the delivery site as
                  well, such as your contact details and any notes you make
                  about the delivery. The company you are delivering to is the
                  data controller for this processing of Personal Data, and you
                  can find more information about this in the Lokate Privacy
                  Notice .
                </p>
              </li>
            </ol>
          </li>
          <li>
            <h2
              style={{
                marginTop: "6.0pt",
                marginRight: "0cm",
                marginBottom: "6.0pt",
                fontSize: "0.8rem",
                fontFamily: '"Arial",sans-serif',
              }}
            >
              Miscellaneous
            </h2>
            <ol
              className="decimal_type"
              style={{
                listStyleType: "decimal",
                fontSize: "0.8rem",
                marginLeft: "20.0pt",
              }}
            >
              <li>
                <p
                  style={{
                    marginTop: "6.0pt",
                    marginRight: "0cm",
                    marginBottom: "6.0pt",
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "0cm",
                    fontSize: "0.8rem",
                    fontFamily: '"Arial",sans-serif',
                    fontWeight: "normal",
                  }}
                >
                  Volvo is the owner/authorized user of all design, text,
                  content, information, selection and arrangement of elements,
                  organization, graphics, design, compilation and other matters
                  related to the Service (<b>"Intellectual Property Rights"</b>
                  ). Your use of the Service does not grant you a license to the
                  Intellectual Property Rights. You are granted a non-exclusive,
                  non-transferable, and non-sublicensable, limited license to
                  access and use the Service. You may not sell, rent, lease,
                  sublicense or redistribute the Service to any third party.
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginTop: "6.0pt",
                    marginRight: "0cm",
                    marginBottom: "6.0pt",
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "0cm",
                    fontSize: "0.8rem",
                    fontFamily: '"Arial",sans-serif',
                    fontWeight: "normal",
                  }}
                >
                  The Service is provided on an “as is” and “as available”
                  basis. No representations or warranties, whether expressed or
                  implied, are made regarding any aspects of the Service,
                  including but not limited to, (i) implied warranties of
                  merchantability, fitness for a particular purpose, title or
                  non-infringement, (ii) the accuracy, availability and
                  accessibility of the Service, or (iii) warranty that the
                  Service will be error-free, free of viruses and other harmful
                  materials, or that the Service will operate without
                  interruption. Due to the nature of the Internet, uninterrupted
                  connection cannot be guaranteed and the feature may
                  accordingly be occasionally unavailable due to lost Internet
                  connection.
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginTop: "6.0pt",
                    marginRight: "0cm",
                    marginBottom: "6.0pt",
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "0cm",
                    fontSize: "0.8rem",
                    fontFamily: '"Arial",sans-serif',
                    fontWeight: "normal",
                  }}
                >
                  You agree to indemnify and hold Volvo, its officers,
                  directors, shareholders, predecessors, successors in interest,
                  employees, agents, subsidiaries and affiliates, harmless from
                  any demands, damage, loss, liability, claims or expenses
                  (including attorneys’ fees) made against Volvo for your use of
                  the Service in violation of these User Terms.
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginTop: "6.0pt",
                    marginRight: "0cm",
                    marginBottom: "6.0pt",
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "0cm",
                    fontSize: "0.8rem",
                    fontFamily: '"Arial",sans-serif',
                    fontWeight: "normal",
                  }}
                >
                  To the fullest extent permitted by law and unless otherwise
                  expressly provided in these User Terms, Volvo shall have no
                  liability for any damage or loss of any kind caused in
                  connection with your use of the Service, regardless of how it
                  was caused and whether such damage or loss was foreseeable or
                  not. This limitation of liability includes but is not limited
                  to liability for any special, indirect, incidental,
                  consequential damage or loss of any kind, regardless of how it
                  was caused and including but not limited to, loss of profit,
                  loss of reputation or goodwill, loss of production, loss of
                  business or business opportunities, loss of revenues or
                  anticipated savings, or loss or corruption of data or
                  information.
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginTop: "6.0pt",
                    marginRight: "0cm",
                    marginBottom: "6.0pt",
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "0cm",
                    fontSize: "0.8rem",
                    fontFamily: '"Arial",sans-serif',
                    fontWeight: "normal",
                  }}
                >
                  You are responsible for ensuring the security of your User
                  Account and login credentials. If you suspect that your User
                  Account may be compromised, you shall immediately notify us.
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginTop: "6.0pt",
                    marginRight: "0cm",
                    marginBottom: "6.0pt",
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "0cm",
                    fontSize: "0.8rem",
                    fontFamily: '"Arial",sans-serif',
                    fontWeight: "normal",
                  }}
                >
                  We reserve the right to update these User Terms at any time
                  and will publish the updated terms in the App and on the
                  Lokate website. In the event of such a change, your continued
                  use of the Service shall be considered an acceptance of the
                  updated Agreement.
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginTop: "6.0pt",
                    marginRight: "0cm",
                    marginBottom: "6.0pt",
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "0cm",
                    fontSize: "0.8rem",
                    fontFamily: '"Arial",sans-serif',
                    fontWeight: "normal",
                  }}
                >
                  You may terminate these User Terms without cause by deleting
                  your User Account and the App. Upon request, we will erase any
                  Personal Data that we have collected on account of your
                  consent, to the extent required by applicable data protection
                  laws.
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginTop: "6.0pt",
                    marginRight: "0cm",
                    marginBottom: "6.0pt",
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "0cm",
                    fontSize: "0.8rem",
                    fontFamily: '"Arial",sans-serif',
                    fontWeight: "normal",
                  }}
                >
                  These User Terms shall be governed by and construed in
                  accordance with the laws of Sweden, excluding its conflict of
                  laws principles providing for the application of the laws of
                  any other jurisdiction.
                </p>
              </li>
              <li>
                <p
                  style={{
                    marginTop: "6.0pt",
                    marginRight: "0cm",
                    marginBottom: "6.0pt",
                    marginLeft: "0cm",
                    textAlign: "justify",
                    textIndent: "0cm",
                    fontSize: "0.8rem",
                    fontFamily: '"Arial",sans-serif',
                    fontWeight: "normal",
                  }}
                >
                  The Swedish courts, with the district court of Gothenburg
                  (Göteborgs tingsrätt) as first instance, will have exclusive
                  jurisdiction to settle any dispute which may arise out of, or
                  in connection with these User Terms. The parties agree to
                  submit to that jurisdiction.
                </p>
              </li>
            </ol>
          </li>
        </ol>
      </div>
    </div>
    <br />
    <br />
    <div className="TextContainer">
      <div>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "12.0pt",
            marginLeft: "0cm",
            textAlign: "justify",
            fontSize: "1.2rem",
            fontFamily: '"Arial",sans-serif',
            fontWeight: "bold",
          }}
        >
          Lokate Privacy Notice
        </p>

        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            textAlign: "justify",
            textIndent: "0cm",
            fontSize: "0.8rem",
            fontFamily: '"Arial",sans-serif',
            fontWeight: "normal",
          }}
        >
          This privacy notice provides information on the processing of personal
          data taking place in connection to Lokate deliveries. The company that
          is using the Lokate service (the <b>“Service”</b>) for receiving
          deliveries is the data controller for any processing of personal data
          carried out in relation to a delivery of goods (referred to as the{" "}
          <b>“Controller”</b>). Please reach out to this company if you have any
          questions in relation to the processing of your personal data. The
          terms
          <b>“personal data”</b>, <b>“processing”</b>, <b>“data controller”</b>,{" "}
          <b>“legal basis”</b> have the meaning described in the EU General Data
          Protection Regulation (<b>“GDPR”</b>).
        </p>
        <h2
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            fontSize: "0.8rem",
            fontFamily: '"Arial",sans-serif',
          }}
        >
          What types of personal data will the Controller collect about you?
        </h2>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            textAlign: "justify",
            textIndent: "0cm",
            fontSize: "0.8rem",
            fontFamily: '"Arial",sans-serif',
            fontWeight: "normal",
          }}
        >
          The Controller will process the following categories of personal data
          in relation to your use of the Service. Please note that the
          Controller will not necessarily process all the data listed below.
          <ul
            style={{
              listStyleType: "disc",
              paddingLeft: "20px",
              paddingTop: "10px",
              paddingBottom: "10px",
              fontSize: "0.8rem",
            }}
          >
            <li style={{ paddingBottom: "5px" }}>
              <b>User data</b>: name, e-mail address, telephone number, company
              name, user account ID, password, IP address, activity log,
              timestamp, user application preferences, and image (image is
              voluntary);
            </li>
            <li style={{ paddingBottom: "5px" }}>
              <b>Driver data</b>: e-mail address, telephone number, and GPS
              location data of the mobile device when transporting goods to a
              delivery site;
            </li>
            <li style={{ paddingBottom: "5px" }}>
              <b>Delivery site data</b>: company name, location, drop points
              (longitude and latitude), image, and contact persons from the
              companies involved in the delivery; and
            </li>
            <li style={{ paddingBottom: "5px" }}>
              <b>Vehicle data</b>: registration number, model, and type.
            </li>
          </ul>
          It is possible for a driver to pause the tracking of GPS location data
          of your mobile device during a transport, however, please note that
          doing so will affect the value and use of the Service.
        </p>
        <h2
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            fontSize: "0.8rem",
            fontFamily: '"Arial",sans-serif',
          }}
        >
          What is the purpose of the processing?
        </h2>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            textAlign: "justify",
            textIndent: "0cm",
            fontSize: "0.8rem",
            fontFamily: '"Arial",sans-serif',
            fontWeight: "normal",
          }}
        >
          The Controller will process the personal data for the purpose of
          ordering, planning, coordinating, and tracking the transport of goods.
        </p>
        <h2
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            fontSize: "0.8rem",
            fontFamily: '"Arial",sans-serif',
          }}
        >
          What legal basis does the Controller have for the processing of
          personal data?
        </h2>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            textAlign: "justify",
            textIndent: "0cm",
            fontSize: "0.8rem",
            fontFamily: '"Arial",sans-serif',
            fontWeight: "normal",
          }}
        >
          The Controller will process your personal data based on its legitimate
          interests to keep track of and coordinate the delivery of goods to its
          delivery sites.
        </p>
        <h2
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            fontSize: "0.8rem",
            fontFamily: '"Arial",sans-serif',
          }}
        >
          From where will the Controller collect your personal data?
        </h2>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            textAlign: "justify",
            textIndent: "0cm",
            fontSize: "0.8rem",
            fontFamily: '"Arial",sans-serif',
            fontWeight: "normal",
          }}
        >
          The Controller will process personal data that you have provided
          yourself as well as personal data collected from your mobile device
          and from your use of the Service.
        </p>
        <h2
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            fontSize: "0.8rem",
            fontFamily: '"Arial",sans-serif',
          }}
        >
          Will the Controller share my personal data?
        </h2>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            textAlign: "justify",
            textIndent: "0cm",
            fontSize: "0.8rem",
            fontFamily: '"Arial",sans-serif',
            fontWeight: "normal",
          }}
        >
          The Controller will normally not share your personal data with anyone
          outside of its group of companies, if it is not required by law,
          regulation or collective bargaining agreement. However, the Controller
          may share your personal data with its suppliers, such as suppliers of
          goods to be delivered and IT suppliers.
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            textAlign: "justify",
            textIndent: "0cm",
            fontSize: "0.8rem",
            fontFamily: '"Arial",sans-serif',
            fontWeight: "normal",
          }}
        >
          The Controller will make reasonable efforts to ensure that your
          personal data is protected and that safeguards required by data
          protection laws are in place. Such safeguard could be external data
          processing agreements based on EU approved Standard Contractual
          Clauses or other safeguards that have been approved by authorities.
        </p>
        <h2
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            fontSize: "0.8rem",
            fontFamily: '"Arial",sans-serif',
          }}
        >
          For how long will the Controller store my personal data?
        </h2>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            textAlign: "justify",
            textIndent: "0cm",
            fontSize: "0.8rem",
            fontFamily: '"Arial",sans-serif',
            fontWeight: "normal",
          }}
        >
          The Controller will store your personal data until six months after it
          has terminated its use of Lokate. If you are a driver, your personal
          data will be processed for the duration of the delivery and will not
          be visible in the delivery history.
        </p>
        <h2
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            fontSize: "0.8rem",
            fontFamily: '"Arial",sans-serif',
          }}
        >
          What data protection rights do I have?
        </h2>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            textAlign: "justify",
            textIndent: "0cm",
            fontSize: "0.8rem",
            fontFamily: '"Arial",sans-serif',
            fontWeight: "normal",
          }}
        >
          According to the GDPR, you have the following data privacy rights:
        </p>
        <ul
          style={{
            listStyleType: "disc",
            paddingLeft: "20px",
            paddingTop: "10px",
            paddingBottom: "10px",
            fontSize: "0.8rem",
          }}
        >
          <li style={{ paddingBottom: "5px" }}>
            Request access to the personal data the Controller processes about
            you.
          </li>
          <li style={{ paddingBottom: "5px" }}>
            Request a rectification of your personal data.
          </li>
          <li style={{ paddingBottom: "5px" }}>
            Object to the processing of your personal data.
          </li>
          <li style={{ paddingBottom: "5px" }}>
            Request the erasure or deletion of your personal data.
          </li>
          <li style={{ paddingBottom: "5px" }}>
            Request the restriction of the processing of your personal data.
          </li>
          <li style={{ paddingBottom: "5px" }}>
            Request portability of your personal data.
          </li>
        </ul>

        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            textAlign: "justify",
            textIndent: "0cm",
            fontSize: "0.8rem",
            fontFamily: '"Arial",sans-serif',
            fontWeight: "normal",
          }}
        >
          The Controller may not always be obliged to comply with a request of
          deletion, restriction, objection or data portability, and an
          assessment will be made in each case of a request from you to exercise
          your rights.
        </p>
        <p
          style={{
            marginTop: "6.0pt",
            marginRight: "0cm",
            marginBottom: "6.0pt",
            marginLeft: "0cm",
            textAlign: "justify",
            textIndent: "0cm",
            fontSize: "0.8rem",
            fontFamily: '"Arial",sans-serif',
            fontWeight: "normal",
          }}
        >
          Also, you have the right to lodge any complaints you may have
          regarding the Controller’s processing of your personal data to a
          supervisory authority. For more information about these rights and how
          to exercise them, please contact the Controller.
        </p>
      </div>
    </div>
  </section>
);
