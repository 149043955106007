import "./Nav.css";
import {
  currentAndroidVersion,
  currentIosVersion,
  currentWebVersion,
} from "./ReleaseNotes";

const Nav = () => {
  //Hide footer if url contains /userterms-privacypolicyTPS
  if (window.location.href.toString().includes("legaldocument-TPS")) {
    return <></>;
  }

  return (
    <div className="footer">
      <ul className="footer-links">
        <li>
          <a href={"/"}>LINKS</a>
        </li>
        <li>
          <a href={"/userterms-privacypolicy"}>USER TERMS AND PRIVACY NOTICE</a>
        </li>
        <li>
          <a href={"/releasenotes"}>RELEASE NOTES</a>
        </li>
      </ul>
      <div className="footer-versions">
        <span>Latest Android Version: 4.00 (138)</span>
        <span>/</span>
        <span>Latest iOS Version: 4.00 (138)</span>
        <span>/</span>
        <span>Latest Web Version: sPRODw43</span>
      </div>
    </div>
  );
};

export default Nav;
