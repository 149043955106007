import React, { useState, useEffect } from "react";
import "../App.css";
import { LegalInfo } from "./LegalInfo";

function LegalDocumentTPS() {
  const [scrollViewHeight, setScrollViewHeight] = useState(
    window.innerHeight - 210
  );

  useEffect(() => {
    const handleResize = () => {
      setScrollViewHeight(window.innerHeight - 210);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="App">
      <main className="App-main">
        <header className="App-header">
          <a href="/">
            <img
              src={`../img/lokate-light-logo.svg`}
              height={40}
              alt={"Lokate"}
            />
          </a>
        </header>
        {LegalInfo}
      </main>
    </section>
  );
}

export default LegalDocumentTPS;